// extracted by mini-css-extract-plugin
export var recentPost = "bM_ml";
export var recentPost__button = "bM_my";
export var recentPost__postCard = "bM_mm";
export var recentPost__postCategory = "bM_mx";
export var recentPost__postClamp = "bM_mw";
export var recentPost__postDate = "bM_mr";
export var recentPost__postDescriprion = "bM_mv";
export var recentPost__postImage = "bM_mp";
export var recentPost__postInfo = "bM_mq";
export var recentPost__postLink = "bM_mn";
export var recentPost__postStats = "bM_ms";
export var recentPost__postTitle = "bM_mt";
export var recentPost__title = "bM_mz";
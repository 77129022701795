// extracted by mini-css-extract-plugin
export var socialBlock = "cz_s6";
export var socialBlock__icon = "cz_tc";
export var socialBlock__iconWrapper = "cz_tb";
export var socialBlock__icon_facebook = "cz_td";
export var socialBlock__icon_instagram = "cz_th";
export var socialBlock__icon_linkedin = "cz_tf";
export var socialBlock__icon_telegram = "cz_tj";
export var socialBlock__icon_tiktok = "cz_tk";
export var socialBlock__icon_twitter = "cz_tg";
export var socialBlock__iconsWrapper = "cz_s9";
export var socialBlock__sprite = "cz_s8";
export var socialBlock__title = "cz_s7";